import React, { useRef, useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import {
	useViewportScroll,
	motion,
	useTransform,
	AnimatePresence,
	useMotionValue,
	animate,
} from "framer-motion";
import { easing } from "../../../util/math";
import { useGetResizer } from "../../../contexts/Resizer";
import {
	spacing,
	screen,
	grid,
	ratio,
	fontSize,
	getTheme,
} from "../../../styles/mixins";
import { design } from "../../../styles/settings";
import ladderImage from "../../../images/cover-ladder.png";
import personFallingImage from "../../../images/falling-woman.png";
import holeImage from "../../../images/hole.svg";
import fallingMask from "../../../images/falling-mask.svg";
import {
	displayH1,
	fontRoslindaleDisplay,
	fontSurt,
	h5,
	richText,
} from "../../../styles/typography";
import { ReactComponent as LogoSvg } from "../../../images/logo.svg";
import { useDefinitionModal } from "../../../contexts/DefinitionModalContext";
import { useUIState } from "../../../contexts/UIStore";

const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	z-index: 1;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Screen = styled.div`
	position: fixed;
	height: 100vh;
	top: 0;
	${grid};
	width: 100vw;
	text-align: center;
	align-items: end;
	justify-items: center;
`;

const Content = styled(motion.div)`
	grid-column: 3 / -3;
	text-align: center;
	position: relative;
	${spacing("marginBottom", "m")};

	@media ${screen("md")} {
		grid-column: 1 / -1;
	}
`;

const SubtitleContainer = styled(motion.div)`
	position: absolute;
	grid-column: 4 / -4;
	text-align: center;
	${spacing("marginBottom", "m")};
	top: 150vh;

	@media ${screen("md")} {
		grid-column: 10 / -10;
	}

	@media ${screen("lg")} {
		grid-column: 16 / -16;
	}
`;

const Subtitle = styled.h2`
	${h5};
	${spacing("marginBottom", "m")};
	${fontRoslindaleDisplay};
	text-transform: uppercase;
	span {
		${fontSurt};
	}
`;

const Credits = styled.div`
	${richText}
`;

const LogoWrapper = styled.div`
	width: 98px;
	display: inline-block;
	${spacing("marginBottom", "m")};

	svg {
		width: 100%;
		height: auto;
		fill: black;
	}
`;

const Title = styled.h1`
	${displayH1};
	${fontSize(6.9)};
	span {
		display: inline-block;
		transform-origin: top right;
		${fontSurt};
	}
`;

const Ladder = styled(motion.div)`
	${ratio(
		"width",
		0,
		{
			mn: { base: 130, ratio: 1 },
			md: { base: 140, ratio: 1 },
			ms: { base: 150, ratio: 1 },
		},
		design
	)}
	position: absolute;
	margin-left: auto;
	left: 0;
	right: 0;
	top: 0;
	margin-right: auto;
	img {
		width: 100%;
	}
`;

const RadialGradient = styled(motion.div)`
	position: fixed;
	height: 500vh;
	width: 138vw;
	top: -100vh;
	left: -19vw;
	pointer-events: none;
	background-image: radial-gradient(
		50% 50% at 50% 50%,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.0127407) 13.27%,
		rgba(0, 0, 0, 0.0485926) 24.51%,
		rgba(0, 0, 0, 0.104) 33.96%,
		rgba(0, 0, 0, 0.175407) 41.89%,
		rgba(0, 0, 0, 0.259259) 48.53%,
		rgba(0, 0, 0, 0.352) 54.15%,
		rgba(0, 0, 0, 0.450074) 58.99%,
		rgba(0, 0, 0, 0.549926) 63.31%,
		rgba(0, 0, 0, 0.648) 67.35%,
		rgba(0, 0, 0, 0.740741) 71.38%,
		rgba(0, 0, 0, 0.824593) 75.63%,
		rgba(0, 0, 0, 0.896) 80.37%,
		rgba(0, 0, 0, 0.951407) 85.84%,
		rgba(0, 0, 0, 0.987259) 92.3%,
		#000000 100%
	);
	will-change: transform;
`;

const PersonContainer = styled(motion.div)`
	height: calc(100vh + 150px);
	position: absolute;
	left: 0;
	right: 0;
	bottom: 1px;
	mask-image: url(${fallingMask});
	mask-position: bottom;
`;

const PersonFalling = styled(motion.div)`
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
	bottom: calc(72vh);
	width: 130px;
	will-change: transform;
	@media ${screen("md")} {
		width: 170px;
	}
	img {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2px;
	}
`;

const Hole = styled(motion.div)`
	position: absolute;
	top: 100vh;
	width: 70vw;
	transform-origin: bottom center;
	will-change: transform;
	@media ${screen("md")} {
		width: 300px;
	}
`;

const HoleImage = styled.img`
	width: 100%;
`;

export default function Cover({ title, subtitle }) {
	const area = useGetResizer();
	const containerRef = useRef();
	const height = 3.25;
	const [refPosition, setRefPosition] = useState(0);
	const [windowHeight, setWindowHeight] = useState(768);
	const holeRef = useRef();
	const [holeHeight, setHoleHeight] = useState(100);
	const personRef = useRef();
	const { setContentKey, setActive } = useDefinitionModal();

	const uiState = useUIState();

	const { scrollY } = useViewportScroll();
	const contentScrollY = useTransform(
		scrollY,
		[0, 2.3 * windowHeight],
		[0, -2.3 * windowHeight]
	);
	const contentAnimationY = useMotionValue(-40);
	// const contentProgressY = useTransform(
	// 	[scrollY, contentScrollY, contentAnimationY],
	// 	([scrollY, scrollProgressY, animationProgressY]) => {
	// 		if (scrollY === 0) {
	// 			return animationProgressY;
	// 		} else {
	// 			return scrollProgressY;
	// 		}
	// 	}
	// );

	const contentProgressY = useTransform(
		[scrollY, contentScrollY, contentAnimationY],
		([scrollY, scrollProgressY, animationProgressY]) => {
			if (scrollY === 0) {
				return animationProgressY;
			} else {
				return scrollProgressY;
			}
		}
	);

	const fallingPersonScrollY = useTransform(
		scrollY,
		[0, windowHeight * 1.2, windowHeight * 4.5],
		[0, 0.2 * windowHeight, 2 * windowHeight]
		// { ease: easing.outQuad }
	);
	const fallingPersonAnimationY = useMotionValue(-500);
	const fallingPersonProgressY = useTransform(
		[scrollY, fallingPersonScrollY, fallingPersonAnimationY],
		([scrollY, scrollProgressY, animationProgressY]) => {
			if (scrollY === 0) {
				return animationProgressY;
			} else {
				return scrollProgressY;
			}
		}
	);

	const fallingPersonProgressRotate = useTransform(
		scrollY,
		[0, windowHeight * 6],
		[-15, -45],
		{ ease: easing.outQuad }
	);

	const gradientProgressScale = useTransform(
		scrollY,
		[0, windowHeight * 9],
		[1, 5],
		{ ease: easing.outQuad }
	);

	const gradientProgressY = useTransform(
		scrollY,
		[0, windowHeight * 3],
		[0, -windowHeight * 0.5],
		{ ease: easing.outQuad }
	);

	const ladderScrollY = useTransform(
		scrollY,
		[0, windowHeight * 3],
		[0, -200],
		{ ease: easing.outQuad }
	);
	const ladderAnimationY = useMotionValue(-300);
	const ladderProgressY = useTransform(
		[scrollY, ladderScrollY, ladderAnimationY],
		([scrollY, scrollProgressY, animationProgressY]) => {
			if (scrollY === 0) {
				return animationProgressY;
			} else {
				return scrollProgressY;
			}
		}
	);

	const holeProgressY = useTransform(
		scrollY,
		[windowHeight * 1.2, windowHeight * 8],
		[0, -2.5 * windowHeight - holeHeight]
	);

	const holeProgressScale = useTransform(
		scrollY,
		[windowHeight * 2.3, windowHeight * 2.9],
		[1, 0]
	);

	const setSizes = () => {
		const position = containerRef.current?.getBoundingClientRect();
		setRefPosition(position);
	};

	useEffect(() => {
		setSizes();
	}, [area]);

	const handleScroll = useCallback(() => {
		if (window.pageYOffset > windowHeight * 0.6) {
			uiState.removeTheme("home-dark");

			uiState.addTheme(getTheme("light"), "home-light");
		} else {
			uiState.removeTheme("home-light");
			uiState.addTheme(getTheme("dark"), "home-dark");
		}
	}, [windowHeight, uiState]);

	useEffect(() => {
		handleScroll();
		uiState.addTheme(getTheme("dark"), "home-dark");
		window.removeEventListener("scroll", handleScroll, true);
		window.addEventListener("scroll", handleScroll, true);
		return function () {
			window.removeEventListener("scroll", handleScroll, true);
		};
	}, [refPosition, handleScroll, uiState]);

	useEffect(() => {
		const handleResize = () => {
			updateWindowHeight();
			setSizes();
			updateHoleHeight();
		};

		const updateWindowHeight = () => {
			setWindowHeight(window.innerHeight);
		};

		const updateHoleHeight = () => {
			setHoleHeight(holeRef.current.getBoundingClientRect().height);
		};

		updateWindowHeight();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
			uiState.clearTheme();
		};
	}, [uiState]);

	useEffect(() => {
		const controls = animate(fallingPersonAnimationY, 0, {
			duration: 1.4,
		});

		return controls.stop;
	});

	useEffect(() => {
		const controls = animate(ladderAnimationY, 0, {
			duration: 1.4,
		});

		return controls.stop;
	});

	useEffect(() => {
		const controls = animate(contentAnimationY, 0, {
			duration: 1.4,
		});

		return controls.stop;
	});

	const handleCreditsClick = (e) => {
		e.preventDefault();
		setContentKey("credits");
		setActive(true);
	};

	return (
		<>
			<AnimatePresence>
				<RadialGradient
					key="gradient"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 1.7 }}
					style={{
						scale: gradientProgressScale,
						y: gradientProgressY,
					}}
				/>
				<Container ref={containerRef} style={{ height: `${height * 100}vh` }}>
					<Screen>
						<Ladder
							style={{
								y: ladderProgressY,
							}}>
							<img src={ladderImage} alt="Ladder" />
						</Ladder>
						<Hole
							ref={holeRef}
							style={{
								y: holeProgressY,
								scale: holeProgressScale,
							}}>
							<HoleImage src={holeImage} alt="Hole" />
							<PersonContainer>
								<PersonFalling
									ref={personRef}
									style={{
										y: fallingPersonProgressY,
										rotate: fallingPersonProgressRotate,
									}}>
									<img src={personFallingImage} alt="Falling person" />
								</PersonFalling>
							</PersonContainer>
						</Hole>
						<Content
							style={{ y: contentProgressY }}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1.4, ease: false }}>
							<Title dangerouslySetInnerHTML={{ __html: title }} />
						</Content>
						<SubtitleContainer
							style={{ y: contentProgressY }}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1.4, ease: false }}>
							<LogoWrapper>
								<LogoSvg />
							</LogoWrapper>
							<Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
							<Credits>
								<button className="meta" onClick={handleCreditsClick}>
									Credits
								</button>
							</Credits>
						</SubtitleContainer>
					</Screen>
				</Container>
			</AnimatePresence>
		</>
	);
}
